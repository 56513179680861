<template>
	<div class="mainBox" ref="gameMain">
		<template v-if="bannerList.length > 0">
			<div class="bannerBox">
				<swiper
				  :spaceBetween="30" 
				  :centeredSlides="true" 
				  :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
				  :pagination='{ "clickable": true }' 
				  :navigation="false" 
					:style="{
					  '--swiper-navigation-color': '#000',
					  '--swiper-pagination-color': '#000'
					}"
				  class="mySwiper"
				  >
				  <swiper-slide v-for="(item,index) in bannerList" :key="index">
						<div class="banner cimg" @click="goGameDetail(item)">
							<img :src="item.gameFrontCover">
						</div>
					</swiper-slide>
				</swiper>
			</div>
			<van-notice-bar color="#409eff" background="#ecf5ff" left-icon="volume-o" :scrollable="false" v-if="noticeList.length > 0">
			  <van-swipe
			    vertical
			    class="noticeSwipe"
			    :autoplay="3000"
			    :touchable="false"
			    :show-indicators="false"
			  >
			    <van-swipe-item v-for="(item,index) in noticeList" :key="index" @click="handleNotice(item)">{{ item.noticeTitle }}</van-swipe-item>
			  </van-swipe>
			</van-notice-bar>
			<div class="secServiceAd" v-if="!isMobile">
				<div class="serviceCon">
					<div class="pcHomeTitle" v-if="!isMobile" style="margin-top: 40px;height: 82px;padding-left: 0;"><img src="~@/assets/images/common/service_title.png"></div>
					<div class="serviceBox">
						<div class="serviceTitle">
							<div class="titleItem">{{ $t('home.serviceTime') }}</div>
							<div class="titleItem">{{ $t('home.serviceGame') }}</div>
							<div class="titleItem">{{ $t('home.serviceList') }}</div>
						</div>
						<div class="serviceList">
							<ul>
								<li v-for="(item,index) in serverList" :key="index">
									<span class="timeItem">{{ parseTime(item.openTime,'{m}-{d} {h}:{i}') }}</span>
									<span class="gameItem">{{ item.gameName }}<i class="hot" v-if="index < 3"></i></span>
									<span class="servieItem">{{ item.serviceName }}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="adCon">
					<div class="pcHomeTitle" v-if="!isMobile" style="margin-top: 40px;"><img src="~@/assets/images/common/coupon_title.png"></div>
					<div class="adBox" v-if="adImg">
						<div class="imgBox cimg" v-if="isFirstPopup" @click="onClickCoupon">
							<img :src="adImgs">
						</div>
						<div class="imgBox cimg" v-else @click="onClickScroll">
							<img :src="adImgs">
						</div>
						<div class="useRule" @click="showRules = !showRules">
							<van-icon name="question-o" style="margin-right: 0.3125rem;" />{{ $t('home.couponRules') }}</div>
					</div>
				</div>
			</div>
			<template v-else>
				<div class="adBox" v-if="adImg">
					<div class="imgBox cimg" v-if="isFirstPopup" @click="onClickCoupon">
						<img :src="adImg">
					</div>
					<div class="imgBox cimg" v-else @click="onClickScroll">
						<img :src="adImg2">
					</div>
					<div class="useRule" @click="showRules = !showRules">
						<van-icon name="question-o" style="margin-right: 0.3125rem;" />{{ $t('home.couponRules') }}</div>
				</div>
			</template>
			<template v-if="isMobile">
				<div class="gameBox" v-if="subscribeGameList.length > 0">
					<div class="titleBox">
						<h4>{{ $t('home.prebookGame') }}</h4>
						<p>{{ $t('home.prebookDesc') }}</p>
					</div>
					<div class="gameList">
						<div class="gameItem" v-for="(item,index) in subscribeGameList" :key="index" @click="goGameDetail(item)">
							<div class="descBox">
								<div class="releaseTime" v-if="item.gameReleaseTime">{{ $t('home.releaseTime') }}:{{parseTime(item.gameReleaseTime,'{y}-{m}-{d} {h}:{i}')}}</div>
								<div class="iconBox cimg">
									<img :src="item.gameIcon">
								</div>
								<div class="detailBox">
									<p class="gameName">
										{{item.gameKrName}}
										<span class="dicon" v-if="item.gmLink"><img src="~@/assets/images/common/gm_icon.png"></span>
										<span class="dicon" v-if="item.googleLink"><img src="~@/assets/images/common/google_icon.png"></span>
										<span class="dicon" v-if="item.onestoreLink"><img src="~@/assets/images/common/onstore_icon.png"></span>
										<span class="dicon" v-if="item.sswUrl"><img src="~@/assets/images/common/samsung_icon.png"></span>
									</p>
									<p class="labels" v-if="item.gameType">
										<!-- <span v-if="item.gameType">{{item.gameType}}</span>
										<span v-if="item.gameKrName">{{item.gameKrName}}</span> -->
										<span v-for="(item,index) in formatTags(item.gameType)" :key="index">{{item}}</span>
									</p>
									<p class="tags">
										<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
									</p>
								</div>
								<!-- <div class="btnBox" v-if="item.pcUrl" @click.stop="handlePlay(item)">
									{{ $t('home.gamePlay') }}
								</div> -->
								<div class="btnBox" @click.stop="handleDownload(item)">
									{{ $t('home.gameDownload') }}
								</div>
							</div>
							<div class="bigImg" v-if="item.gameFrontCover">
								<img :src="item.gameFrontCover">
							</div>
						</div>
					</div>
				</div>
				<div class="gameBox" v-if="recommendGameList.length > 0">
					<div class="titleBox">
						<h4>{{ $t('home.recommendGame') }}</h4>
						<p>{{ $t('home.recommendDesc') }}</p>
					</div>
					<div class="gameList">
						<div class="gameItem" v-for="(item,index) in recommendGameList" :key="index" @click="goGameDetail(item)">
							<div class="descBox">
								<div class="releaseTime" v-if="item.gameReleaseTime">{{ $t('home.releaseTime') }}:{{parseTime(item.gameReleaseTime,'{y}-{m}-{d} {h}:{i}')}}</div>
								<div class="iconBox cimg">
									<img :src="item.gameIcon">
								</div>
								<div class="detailBox">
									<p class="gameName">
										{{item.gameKrName}}
										<span class="dicon" v-if="item.gmLink"><img src="~@/assets/images/common/gm_icon.png"></span>
										<span class="dicon" v-if="item.googleLink"><img src="~@/assets/images/common/google_icon.png"></span>
										<span class="dicon" v-if="item.onestoreLink"><img src="~@/assets/images/common/onstore_icon.png"></span>
										<span class="dicon" v-if="item.sswUrl"><img src="~@/assets/images/common/samsung_icon.png"></span>
									</p>
									<p class="labels" v-if="item.gameType">
										<!-- <span v-if="item.gameType">{{item.gameType}}</span>
										<span v-if="item.gameKrName">{{item.gameKrName}}</span> -->
										<span v-for="(item,index) in formatTags(item.gameType)" :key="index">{{item}}</span>
									</p>
									<p class="tags">
										<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
									</p>
								</div>
								<!-- <div class="btnBox" v-if="item.pcUrl" @click.stop="handlePlay(item)">
									{{ $t('home.gamePlay') }}
								</div> -->
								<div class="btnBox" @click.stop="handleDownload(item)">
									{{ $t('home.gameDownload') }}
								</div>
							</div>
							<div class="bigImg" v-if="item.gameFrontCover">
								<img :src="item.gameFrontCover">
							</div>
						</div>
					</div>
				</div>
				<div class="gameBox" v-if="hotGameList.length > 0">
					<div class="titleBox">
						<h4>{{ $t('home.hotGame') }}</h4>
						<p>{{ $t('home.hotDesc') }}</p>
					</div>
					<div class="gameList">
						<div class="gameItem" v-for="(item,index) in hotGameList" :key="index" @click="goGameDetail(item)">
							<div class="descBox">
								<div class="releaseTime" v-if="item.gameReleaseTime">{{ $t('home.releaseTime') }}:{{parseTime(item.gameReleaseTime,'{y}-{m}-{d} {h}:{i}')}}</div>
								<div class="iconBox cimg">
									<img :src="item.gameIcon">
								</div>
								<div class="detailBox">
									<p class="gameName">
										{{item.gameKrName}}
										<span class="dicon" v-if="item.gmLink"><img src="~@/assets/images/common/gm_icon.png"></span>
										<span class="dicon" v-if="item.googleLink"><img src="~@/assets/images/common/google_icon.png"></span>
										<span class="dicon" v-if="item.onestoreLink"><img src="~@/assets/images/common/onstore_icon.png"></span>
										<span class="dicon" v-if="item.sswUrl"><img src="~@/assets/images/common/samsung_icon.png"></span>
									</p>
									<p class="labels" v-if="item.gameType">
										<!-- <span v-if="item.gameType">{{item.gameType}}</span>
										<span v-if="item.gameKrName">{{item.gameKrName}}</span> -->
										<span v-for="(item,index) in formatTags(item.gameType)" :key="index">{{item}}</span>
									</p>
									<p class="tags">
										<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
									</p>
								</div>
								<!-- <div class="btnBox" v-if="item.pcUrl" @click.stop="handlePlay(item)">
									{{ $t('home.gamePlay') }}
								</div> -->
								<div class="btnBox" @click.stop="handleDownload(item)">
									{{ $t('home.gameDownload') }}
								</div>
							</div>
							<div class="bigImg" v-if="item.gameFrontCover">
								<img :src="item.gameFrontCover">
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- pc端 -->
			<template v-else>
				<div class="pcHomeItem l30">
				    <div class="pcHomeTitle"><img src="~@/assets/images/common/games_title.png"></div>
					  <div class="list">
						  <ul>
							  <li v-for="(item,index) in allGame()" :key="index">
								  <a @click="goGameDetail(item)">
									  <div class="imgbox cimg">
										  <img :src="item.gameFrontCover">
									  </div>
									  <div class="gameDescBox">
										  <div class="releaseTime" v-if="item.gameReleaseTime">{{ $t('home.releaseTime') }}:{{parseTime(item.gameReleaseTime,'{y}-{m}-{d} {h}:{i}')}}</div>
										  <div class="detailBox">
											  <div class="avatar">
												  <img :src="item.gameIcon">
											  </div>
											  <div class="titles">
												  <h4>{{item.gameKrName}}</h4>
												  <!-- <p>{{item.gameType}}</p> -->
												  <p class="labels" v-if="item.gameType">
												  	<span v-for="(item,index) in formatTags(item.gameType)" :key="index">{{item}}</span>
												  </p>
												  <p class="tags">
												  	<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
												  </p>
											  </div>
											  <div class="linkBox" @click.stop="handleDownload(item)">
												  <a v-if="item.gmLink" href="javascript:;"><img src="~@/assets/images/common/gm_icon.png"></a>
												  <a v-if="item.googleLink" href="javascript:;"><img src="~@/assets/images/common/google_icon.png"></a>
												  <a v-if="item.onestoreLink" href="javascript:;"><img src="~@/assets/images/common/onstore_icon.png"></a>
												  <a v-if="item.sswUrl" href="javascript:;"><img src="~@/assets/images/common/samsung_icon.png"></a>
											  </div>
										  </div>
										  <div class="downloadBox">
											  <!-- <a class="btn">Android</a> -->
											  <div class="btns border" @click.stop="handleDownload(item)">
											  	<i class="mIcon"></i>{{ $t('home.gameDownload') }}
											  </div>
											  <!-- pc端下载地址判断未加 -->
											  <template v-if="item.pcUrl && item.ldUrl">
												  <div class="btns" @click.stop="handlePcDownload(item)">
													  <i class="pcIcon"></i>{{ $t('home.pcDownload') }}
												  </div>
													<div class="useRule" @click.stop="showPcRules = !showPcRules">
														<van-icon name="question-o" style="margin-right: 0.3125rem;" />{{ $t('home.couponRules') }}
													</div>
												</template>
										  </div>
										  
									  </div>
								  </a>
							  </li>
						  </ul>
					  </div>
				</div>
				<div class="pcHomeItem l50">
				    <div class="pcHomeTitle"><img src="~@/assets/images/common/hot_title.png"></div>
				    <div class="iconlist">
						  <ul>
							  <li v-for="(item,index) in hotGameList" :key="index">
								  <a @click="goGameDetail(item)">
									  <div class="gameDescBox">
										  <div class="releaseTime" v-if="item.gameReleaseTime">{{ $t('home.releaseTime') }}:{{parseTime(item.gameReleaseTime,'{y}-{m}-{d} {h}:{i}')}}</div>
										  <div class="detailBox">
											  <div class="avatar" style="width: 72px;height: 72px;">
												  <img :src="item.gameIcon">
											  </div>
											  <div class="titles">
												  <h4>{{item.gameKrName}}</h4>
												  <!-- <p>{{item.gameType}}</p> -->
												  <p class="labels" v-if="item.gameType">
												  	<span v-for="(item,index) in formatTags(item.gameType)" :key="index">{{item}}</span>
												  </p>
												  <p class="tags">
												  	<span v-for="(item,index) in formatTags(item.tags)" :key="index">{{item}}</span>
												  </p>
											  </div>
											  <div class="linkBox" @click.stop="handleDownload(item)">
												  <a v-if="item.gmLink" href="javascript:;"><img src="~@/assets/images/common/gm_icon.png"></a>
												  <a v-if="item.googleLink" href="javascript:;"><img src="~@/assets/images/common/google_icon.png"></a>
												  <a v-if="item.onestoreLink" href="javascript:;"><img src="~@/assets/images/common/onstore_icon.png"></a>
												  <a v-if="item.sswUrl" href="javascript:;"><img src="~@/assets/images/common/samsung_icon.png"></a>
											  </div>
											  
										  </div>
										  <div class="downloadBox">
											  <!-- <a class="btn">Android</a> -->
											  <div class="btns border" @click.stop="handleDownload(item)">
											  	<i class="mIcon"></i>{{ $t('home.gameDownload') }}
											  </div>
											  <!-- pc端下载地址判断未加 -->
											  <template v-if="item.pcUrl && item.ldUrl">
												  <div class="btns" @click.stop="handlePcDownload(item)">
													  <i class="pcIcon"></i>{{ $t('home.pcDownload') }}
												  </div>
												  <div class="useRule" @click.stop="showPcRules = !showPcRules">
													<van-icon name="question-o" style="margin-right: 0.3125rem;" />{{ $t('home.couponRules') }}
												  </div>
											  </template>
										  </div>
															  
									  </div>
									 
								  </a>
							  </li>
						  </ul>
				    </div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="emptyBox">
				<van-loading color="#51bac0" />
			</div>
		</template>
		
		<!-- 下载弹窗 -->
		<van-popup v-model:show="showDownload" round teleport="body" @closed="clearActive" >
			<popupDownload v-if="showDownload" :gameInfo="activeItem" />
		</van-popup>
		
		<!-- 下载PC版弹窗 -->
		<van-popup v-model:show="showPcDownload" round teleport="body" @closed="clearActive" :style="{ borderRadius: '28px' }" >
			<popupPcDownload v-if="showPcDownload" :gameInfo="activeItem" />
		</van-popup>
		
		<!-- PC版下载使用说明 -->
		<van-popup v-model:show="showPcRules" round teleport="body" >
			<div class="guidesBox">
				<div class="divide">
					<div class="titletxt">
						<p class="title">{{ $t('home.couponRules') }}</p>
					</div>
				</div>
				<div style="max-height: 70vh;overflow-y: scroll;">
					<div class="rulesItem">
						<img :src="pcdownloadRules">
					</div>
					
				</div>
			</div>
		</van-popup>
		
		<!-- 代金券使用说明 -->
		<van-popup v-model:show="showRules" round teleport="body" >
			<div class="guidesBox">
				<div class="divide">
					<div class="titletxt">
						<p class="title">{{ $t('home.couponRules') }}</p>
					</div>
				</div>
				<div style="max-height: 70vh;overflow-y: scroll;">
					<div class="rulesItem">
						<img :src="couponRules">
					</div>
					
				</div>
			</div>
		</van-popup>
		
		<popupAddScreen :showCoupons="true" :statdes="`首页弹窗按钮`" />
		
	</div>
</template>

<script setup>
	import { Swiper, SwiperSlide } from 'swiper/vue';
	
	import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
	SwiperCore.use([Autoplay,Pagination,Navigation]);
	
	import { getCurrentInstance,onMounted,ref,computed,reactive } from 'vue'
	import { useRouter } from 'vue-router';
	import useConfigStore from '@/store/modules/config'
	import useUserStore from '@/store/modules/user'
	import { getGameBanner, getGameList, getNoticeList, getServerList } from "@/api/index";
	import popupAddScreen from '@/view/coupon/popup.vue'
	import popupDownload from '@/components/Popup/download.vue'
	import popupPcDownload from '@/components/Popup/pcdownload.vue'
	
	const { proxy } = getCurrentInstance();
	// Import the functions you need from the SDKs you need
	import { initializeApp } from "firebase/app";
	import { getAnalytics, logEvent } from "firebase/analytics";
	// Initialize Firebase
	const app = initializeApp(proxy.partten.firebaseConfig);
	const analytics = getAnalytics(app);
	
	const router = useRouter();
	const osType = ref(useUserStore().ostype);//游戏横竖屏
	const gameMain = ref(null)
	const bannerList = ref([])
	const recommendGameList = ref([])
	const hotGameList = ref([])
	const subscribeGameList = ref([])
	const noticeList = ref([])
	const showRules = ref(false)
	const showPcRules = ref(false)
	const showDownload = ref(false)
	const showPcDownload = ref(false)
	const activeItem = ref({})
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	const lang = proxy.$i18n.locale
	const adImg = require('@/assets/images/'+lang+'/ad_coupon.png')
	const adImg2 = require('@/assets/images/'+lang+'/ad_coupon2.png')
	const adImgs = require('@/assets/images/'+lang+'/adshort_coupon.png')
	const gmImg = require('@/assets/images/'+lang+'/gm_download.png')
	const googleImg = require('@/assets/images/'+lang+'/google_download.png')
	const onestoreImg = require('@/assets/images/'+lang+'/onestore_download.png')
	
	const downloadTips = require('@/assets/images/'+lang+'/coupon_tips.png')
	const couponRules = require('@/assets/images/'+lang+'/coupon_rule.jpg')
	const pcdownloadRules = require('@/assets/images/'+lang+'/pcsetup.png')
	
	const isFirstPopup = computed(() => {
		return useConfigStore().isFirstPopup
	})
	const params = ref({})
	const queryParams = reactive({
		pageNum: 1,
		pageSize: 10
	})
	const total = ref(0)
	const serverList = ref([])
	onMounted(() => {
		const query = router.currentRoute.value.params
		params.value = Object.assign({},query)
			
		init()
	})
	const init = () => {
		useConfigStore().setSearch(1)
		
		getGameBanner().then((response) => {
			bannerList.value = response.data;
		})
		getGameList().then((response) => {
			const { hotGame, recommendGame, subscribeGame } = response.data
			recommendGameList.value = recommendGame || []
			hotGameList.value = hotGame || []
			subscribeGameList.value = subscribeGame || []
		})
		getNoticeList({}).then((res) => {
			noticeList.value = res.data
		})
		
		getServerList(queryParams).then((response) => {
			serverList.value = response.rows
			total.value = response.total
		})
	}
	const allGame = () => {
		let arr = []
		arr = subscribeGameList.value.concat(recommendGameList.value)
		return arr
	}
	const handleNotice = (row) => {
		router.push({
		  path:'/game/'+row.gameId,
		  query: { noticeId: row.id }
		});
	}
	const onClickCoupon = () => {
		router.push({
		  name:'Coupon',
		  params: params.value
		});
	}
	const onClickScroll = () => {
		logEvent(analytics, 'home_ad', { des: '代金券'})
		gameMain.value.scrollTop = 350
		gameMain.value.scrollIntoView({ behavior: 'smooth' })
	}
	const goGameDetail = (row) => {
		router.push({
		  path:'/game/'+row.gameId,
		  params: params.value
		});
	}
	const formatTags = (str) => {
		if(!str){
			return []
		}
		if(str.indexOf(',') > -1){
			return str.split(',')
		}else{
			return [str]
		}
		
	}
	const setDownloadLog = (type) => {
		logEvent(analytics, 'download_'+type+'_'+activeItem.value.gameId, { des: activeItem.value.gameName })
	}
	const handleDownload = (row) => {
		if(row.gmLink || row.googleLink || row.onestoreLink){
			activeItem.value = Object.assign({},row)
			showDownload.value = true
		}else{
			proxy.$toast('no download link')
		}
	}
	const handlePcDownload = (row) => {
		activeItem.value = Object.assign({},row)
		showPcDownload.value = true
	}
	const handlePlay = (row) => {
		if(isMobile.value){
			router.push({
			  path:'/gameplay/'+row.gameId,
			  params: params.value
			});
		}else{
			window.open(row.pcUrl,'_blank')
		}
	}
	const clearActive = () => {
		activeItem.value = {}
	}
	
</script>

<style lang="less" scoped>
	.mainBox{
		width: 100%;height: calc(100% - 6rem);;
		overflow-y: scroll;
	}
	.noticeSwipe{
		height: 40px;line-height: 40px;
	}
	.adBox{
		width: 100%;background: linear-gradient(to bottom,transparent,#fff);padding: 0.75rem 0 0;
		position: relative;
		.imgBox{
			width: calc(100% - 1.5rem);margin: 0 0.75rem;
			height: 6.6rem;border-radius: 0.3125rem;font-size: 0;
		}
		.useRule{
			position: absolute;right: 1rem;bottom: -1rem;font-size: 0.8125rem;cursor: pointer;
		}
	}
	.pcbox{
		.secServiceAd{
			display: flex;align-items: center;justify-content: center;
			.serviceCon{
				width: 33%;height: 418px;overflow: hidden;
				padding-left: 20px;padding-right: 10px;box-sizing: border-box;
				.serviceBox{
					width: 100%;height: calc(100% - 125px);
					border: 1px solid #ccc;background: #fff;border-radius: 5px;
					.serviceTitle{
						width: 100%;height: 36px;
						background: url('~@/assets/images/common/service_titlebg.png') no-repeat;
						background-size: 100%;color: #fff;
						display: flex;align-items: center;justify-content: center;
						.titleItem{
							width: 40%;font-size: 14px;text-align: center;padding: 0 20px;font-weight: 500;
							&:first-child{
								width: 30%;text-align: left;
							}
							&:last-child{
								width: 30%;text-align: right;
							}
						}
					}
					.serviceList{
						width: 100%;height: calc(100% - 36px);overflow: hidden;
						padding: 8px 14px;box-sizing: border-box;
						ul{
							margin: 0;padding: 0;width: 100%;height: 100%;
							overflow-y: scroll;
							li{
								font-size: 14px;padding: 6px 0;
								display: flex;align-items: center;justify-content: center;
								span{
									display: inline-block;
								}
								.timeItem{
									width: 60px;flex-shrink: 0;color: #737373;
								}
								.gameItem{
									width: 100%;color: #0e0d0d;
									.hot{
										display: inline-block;width: 32px;height: 10px;
										background: url('~@/assets/images/common/icon_hot.png') no-repeat;
										background-size: cover;margin-left: 2px;
									}
								}
								.servieItem{
									width: 70px;flex-shrink: 0;overflow: hidden;margin-left: 12px;
									color: #f44f77;
								}
							}
							li + li{
								border-top: 1px dashed #eee;
							}
						}
					}
				}
			}
			.adCon{
				flex: 1;
				height: 418px;
			}
		}
		.adBox{
			background: transparent;padding: 42px 20px 3px;box-sizing: border-box;
			.imgBox{
				width: 100%;
				height: 286px;margin: 0 auto;
			}
		}
		.labels{
			font-size: 12px;color: #6b6b6b;margin-bottom: 5px;
			display: inline-flex;align-items: center;width: 100%;
			span{
				display: inline-block;height: 12px;line-height: 12px;
			}
			span + span{
				padding-left: 5px;
				border-left: 1px solid #6b6b6b;margin-left: 5px;
			}
		}
		.tags{
			display: inline-flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;
			overflow: auto;white-space: pre-wrap;
			span{
				display: inline-block;border: 1px solid #f42ab4;color: #f42ab4;
				font-size: 12px;padding: 2px 6px;border-radius: 5px;line-height: 16px;margin: 2px 4px 2px 0;
				&:nth-child(even){
					border: 1px solid #FB9823;color: #FB9823;
				}
			}
			
		}
	}
	.gameBox{
		background: #fff;
		.titleBox{
			padding-top: 1rem;margin-bottom: 0.625rem;
			h4{
				font-size: 1.25rem;color: #303133;
			}
			p{
				font-size: 0.8125rem;color: #90939A;
			}
		}
		.gameList{
			margin-top: 0.9375rem;
			.gameItem{
				margin-bottom: 0.9375rem;
				.descBox{
					display: flex;align-items: center;position: relative;
					.releaseTime{
						position: absolute;top: -5px;right: 0;font-size: 12px;line-height: 15px;color: #999;
					}
					.iconBox{
						flex-shrink: 0;width: 3.5rem;height: 3.5rem;border-radius: 0.75rem;
						margin-right: 0.5rem;
					}
					.detailBox{
						flex: 1;
						.gameName{
							font-size: 0.9375rem;font-weight: 500;color: #303133;
							display: flex;align-items: center;
							.dicon{
								display: inline-block;width: 1.125rem;height: 1.125rem;margin-left: 0.5rem;
								img{
									width: 100%;
								}
							}
						}
						.labels{
							font-size: 0.8125rem;color: #6b6b6b;margin-bottom: 0.1875rem;
							display: inline-flex;align-items: center;width: 100%;
							span{
								display: inline-block;height: 0.75rem;line-height: 0.75rem;
							}
							span + span{
								padding-left: 0.3125rem;
								border-left: 0.0625rem solid #6b6b6b;margin-left: 0.3125rem;
							}
						}
						.tags{
							display: inline-flex;align-items: center;justify-content: flex-start;
							span{
								display: inline-block;border: 1px solid #f42ab4;color: #f42ab4;
								font-size: 0.75rem;padding: 0 0.3125rem;border-radius: 0.3125rem;
								&:nth-child(even){
									border: 1px solid #FB9823;color: #FB9823;
								}
							}
							span + span{
								margin-left: 0.3125rem;
							}
						}
					}
					.btnBox{
						flex-shrink: 0;margin-left: 0.5rem;font-size: 0.8125rem;
						background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
						padding: 0.25rem 0.625rem;border-radius: 0.3125rem;
					}
				}
				.bigImg{
					width: 100%;height: auto;border-radius: 0.75rem;margin-top: 0.625rem;
					overflow: hidden;font-size: 0;
					img{
						width: 100%;
					}
				}
			}
		}
	}
	.gameBox + .gameBox{
		.titleBox{
			border-top: 1px solid #e7e8ea;
		}
	}
	
</style>