<template>
	<div>
		<van-overlay :show="showCoupon" z-index="1111">
		  <div class="couponBox">
		    <img :src="couponImg">
			<div class="btnBox">
				<div class="closeBtn" @click="showCoupon = false">
					<van-icon name="close" color="#fff" size="36" />
				</div>
				<div class="desktopBox" @click.stop="addToHome">
					<img :src="couponBtn">
				</div>
			</div>
			
		  </div>
		</van-overlay>
		
		<van-popup v-model:show="showGuides" round teleport="body" :close-on-click-overlay="false" >
			<div class="guidesBox" v-if="isMobile">
				<div class="divide">
					<div class="titleicon">
						<img src="~@/assets/images/common/gm_icon.png">
					</div>
					<div class="titletxt">
						<p class="title">{{ $t('home.guidesTitle') }}</p>
						<p class="txt">{{ $t('home.guidesText') }}</p>
					</div>
				</div>
				<div style="max-height: 70vh;overflow-y: scroll;">
					<div class="guidesItem" v-for="(item,index) in activeStepItem" :key="index">
						<span class="sortTag"><b>{{parseInt(index+1)}}</b></span>
						<div class="dots">
							<ul>
								<li v-for="i in 8" :key="i" :class="`dots-i`+i"></li>
							</ul>
						</div>
						<p>{{item.txt}}</p>
						<img :src="item.img">
					</div>
					<div class="btnBox" @click="handleSteps">
						{{ $t('home.btnText') }}
					</div>
				</div>
			</div>
			<div v-else class="guidesBox">
				<img class="pcsteps" :src="pcsteps" @click="handleSteps">
			</div>
		</van-popup>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed,defineProps,defineExpose } from 'vue'
	import { useRouter } from 'vue-router';
	import useConfigStore from '@/store/modules/config'
	import useUserStore from '@/store/modules/user'
	const { proxy } = getCurrentInstance();
	// Import the functions you need from the SDKs you need
	import { initializeApp } from "firebase/app";
	import { getAnalytics, logEvent } from "firebase/analytics";
	// Initialize Firebase
	const app = initializeApp(proxy.partten.firebaseConfig);
	const analytics = getAnalytics(app);
	
	const props = defineProps({
		showCoupons: {
			type: Boolean,
			default: false
		},
		statdes: {
			type: String,
			default: ''
		}
	})
	const lang = proxy.$i18n.locale
	const showCoupon = ref(false)
	const showGuides = ref(false)
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const isFirstPopup = computed(() => {
		return useConfigStore().isFirstPopup
	})
	const deferredPrompt = ref(null);
	const activePlat = ref('')
	const activeStep = ref(0)
	const activeStepItem = ref([])
	const couponImg = require('@/assets/images/'+lang+'/coupon_popup.png')
	const couponBtn = require('@/assets/images/'+lang+'/coupon_popup_btn.png')
	const pcsteps = require('@/assets/images/'+lang+'/pc_steps.png')
	const steps = {
		'samsung': [
			{ img: require('@/assets/images/'+lang+'/samsung_step1.png'), txt: '우측 하단 동그라미 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step2.png'), txt: '현재 페이지 추가를 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step3.png'), txt: '홈 화면을 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step4.png'), txt: '추가를 클릭하기'},
		],
		'naver': [
			{ img: require('@/assets/images/'+lang+'/naver_step1.png'), txt: '우측 하단 동그라미 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/naver_step2.png'), txt: '홈 화면에 추가를 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/naver_step3.png'), txt: '추가를 클릭하기'},
		],
		'ios': [
			{ img: require('@/assets/images/'+lang+'/ios_step1.png'), txt: '‘공유‘ 버튼 클릭'},
			{ img: require('@/assets/images/'+lang+'/ios_step2.png'), txt: '‘홈 화면에 추가‘ 클릭'},
			{ img: require('@/assets/images/'+lang+'/ios_step3.png'), txt: '‘추가‘ 버튼 클릭'},
		]
	}
	onMounted(() => {
		const explorer = window.navigator.userAgent
		window.addEventListener(`beforeinstallprompt`, (e) => {
		  // 阻止 Chrome 67 及更早版本自动显示提示
		  e.preventDefault();
		  // 将事件引用保存下来，以便稍后使用
		  deferredPrompt.value = e;
		});
		if(!deferredPrompt.value){
			if(explorer.indexOf('NAVER') >= 0){
				activePlat.value = 'naver'
			}else if(explorer.indexOf('iPhone') >= 0){
				activePlat.value = 'ios'
			}else{
				activePlat.value = 'samsung'
			}
			activeStepItem.value = steps[activePlat.value]
		}
			
		init()
	})
	const init = () => {
		if(useConfigStore().isFirstPopup && props.showCoupons){
			// 更新界面，显示出一个 `添加到主屏幕` 的按钮
			showCoupon.value = true
		}
	}
	const addToHome = () => {
		logEvent(analytics, 'home_popupBtn', { des: props.statdes })
		
		showCoupon.value = false
		useConfigStore().setFristPopup(0)
		if(deferredPrompt.value){
			deferredPrompt.value.prompt();
			deferredPrompt.value.userChoice.then((choiceResult) => {
				console.log(choiceResult)
				deferredPrompt.value = null
			})
		}else{
			showGuides.value = true
		}
	}
	const handleSteps = () => {
		showGuides.value = false
	}
	defineExpose({
		addToHome
	})
</script>

<style lang="less" scoped>
	.couponBox{
		width: 100%;height: 100%;position: relative;margin: 0 auto;
		max-width: 26.25rem;display: flex;align-items: center;
		img{
			width: 100%;
		}
		.btnBox{
			position: absolute;width: 18.75rem;height: 18.75rem;
			left: 50%;top: 50%;transform: translate(-50%,-50%);
			.closeBtn{
				position: absolute;right: -10%;top: -10%;
			}
			.desktopBox{
				position: absolute;left: 50%;bottom: -4%;transform: translate(-50%,0);
				width: 90%;
			}
		}
		
	}
</style>